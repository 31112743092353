import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import masks from './modules/masks';
import options from './modules/options';
import popups from './modules/popups';
import articles from './modules/articles';
import switches from './modules/switches';
import comments from './modules/comments';

const dataState = createPersistedState({
    paths: ['auth.localStorage'],
    key: `wikiVuex_${process.env.VUE_APP_PATH_FRONT}`,
});
export default createStore({
    state: {
    },
    mutations: {
        setNotifyPageVersion(state, data) {
            state.notifyPageVersion = data;
        },
    },
    actions: {
    },
    modules: {
        auth,
        masks,
        options,
        popups,
        articles,
        switches,
        comments,
    },
    plugins: [dataState],
});

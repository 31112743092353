import axios from 'axios';
import api from '@/helpers/api';

const state = {
    items: {},
    titleTemplate: '%s',
};

function detectOS() {
    const thisUserAgent = window.navigator.userAgent;
    return thisUserAgent.indexOf('iPhone OS') !== -1 || thisUserAgent.indexOf('Windows NT 6.1') !== -1 || thisUserAgent.indexOf('iPad') !== -1 || (thisUserAgent.indexOf('Macintosh') > -1 && 'ontouchend' in document);
}

// getters
const getters = {
    setRouble() {
        return detectOS() ? 'Р' : '₽';
    },
};

const actions = {
    getList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_PATH}/public/options/`, api.axiosConfig()).then((response) => {
                if (typeof response.data === 'object' && +response.data.error === 0) {
                    context.commit('setOption', response.data.result);
                    resolve();
                }
            }).catch(() => {
                context.commit('popups/open', { name: context.rootState.popups.items.somethingWentWrong.name }, { root: true });
                reject();
            });
        });
    },
};

const mutations = {
    setOption(state, data) {
        if (typeof data === 'object') {
            state.items = data.items;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

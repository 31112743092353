<template>
    <transition
        name="fade-notify"
        :enter-active-class="transition.enter"
        :leave-active-class="transition.leave"
    >
        <div
            v-show="isActive"
            class="notify-popup c-toast"
            :class="[`c-toast--${type}`, `c-toast--${position}`]"
            @mouseover="toggleTimer(true)"
            @mouseleave="toggleTimer(false)"
            @click="click"
            role="alert"
        ><svg-icon :name="'check2'" :width="24" :height="24" class="text-color-green mr4"></svg-icon><span v-html="message"></span></div>
    </transition>
</template>
<style lang="stylus">
    @import './themes/default/index.styl';
    @import './themes/default/toast.styl';
</style>
<script>
import SvgIcon from '@/components/SvgIcon.vue';
import { removeElement } from './helpers/remove-element';
import Timer from './helpers/timer';
import Positions, { definePosition } from './defaults/positions';
import eventBus from './helpers/event-bus';

export default {
    name: 'toast',
    components: {
        SvgIcon,
    },
    props: {
        message: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'default',
        },
        position: {
            type: String,
            default: Positions.BOTTOM_RIGHT,
            validator(value) {
                return Object.values(Positions).includes(value);
            },
        },
        maxToasts: {
            type: [Number, Boolean],
            default: false,
        },
        duration: {
            type: [Number, Boolean],
            default: 4000,
        },
        dismissible: {
            type: Boolean,
            default: true,
        },
        queue: {
            type: Boolean,
            default: false,
        },
        pauseOnHover: {
            type: Boolean,
            default: true,
        },
        useDefaultCss: {
            type: Boolean,
            default: true,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        onClick: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            isActive: false,
            parentTop: null,
            parentBottom: null,
            isHovered: false,
            timer: null,
        };
    },
    beforeMount() {
        this.createParents();
        this.setDefaultCss();
        this.setupContainer();
    },
    mounted() {
        this.showNotice();
        eventBus.$on('toast-clear', this.close);
    },
    methods: {
        createParents() {
            this.parentTop = document.querySelector('.c-toast-container--top');
            this.parentBottom = document.querySelector('.c-toast-container--bottom');

            if (this.parentTop && this.parentBottom) return;

            if (!this.parentTop) {
                this.parentTop = document.createElement('div');
                this.parentTop.className = 'c-toast-container c-toast-container--top';
            }

            if (!this.parentBottom) {
                this.parentBottom = document.createElement('div');
                this.parentBottom.className = 'c-toast-container c-toast-container--bottom';
            }
        },
        setDefaultCss() {
            const type = this.useDefaultCss ? 'add' : 'remove';
            this.parentTop.classList[type]('v--default-css');
            this.parentBottom.classList[type]('v--default-css');
        },
        setupContainer() {
            const container = document.body;
            container.appendChild(this.parentTop);
            container.appendChild(this.parentBottom);
        },
        shouldQueue() {
            if (!this.queue && this.maxToasts === false) {
                return false;
            }

            if (this.maxToasts !== false) {
                return (
                    this.maxToasts
                    <= this.parentTop.childElementCount + this.parentBottom.childElementCount
                );
            }

            return (
                this.parentTop.childElementCount > 0
                || this.parentBottom.childElementCount > 0
            );
        },
        showNotice() {
            if (this.shouldQueue()) {
                this.queueTimer = setTimeout(this.showNotice, 250);
                return;
            }

            this.correctParent.insertAdjacentElement('afterbegin', this.$el);
            this.isActive = true;

            this.timer = this.duration !== false ? new Timer(this.close, this.duration) : null;
        },
        click() {
            this.onClick.apply(null);

            if (this.dismissible) {
                this.close();
            }
        },
        toggleTimer(newVal) {
            if (this.timer && this.pauseOnHover) {
                if (newVal) {
                    this.timer.pause();
                } else {
                    this.timer.resume();
                }
            }
        },
        stopTimer() {
            if (this.timer) {
                this.timer.stop();
            }
            clearTimeout(this.queueTimer);
        },
        close() {
            this.stopTimer();
            this.isActive = false;
            setTimeout(() => {
                this.onClose.apply(null);
                removeElement(this.$el);
            }, 150);
        },
    },
    computed: {
        correctParent() {
            return definePosition(this.position, this.parentTop, this.parentBottom);
        },
        transition() {
            return definePosition(
                this.position,
                {
                    enter: 'fadeInDown',
                    leave: 'fadeOut',
                },
                {
                    enter: 'fadeInUp',
                    leave: 'fadeOut',
                },
            );
        },
    },
    beforeUnmount() {
        eventBus.$off('toast-clear', this.close);
    },
};
</script>
<style lang="sass">
.fade-notify-enter-active, .fade-notify-leave-active
    transition: all 0.5s
.fade-notify-enter-from, .fade-notify-leave-to
    opacity: 0
    transform: translateY(50px)
.notify-popup
    font: inherit
    padding: 16px 8px
    border-radius: 4px
    background-color: #fff
    color: #545454
    box-shadow: 0px 0px 12px rgba(36, 99, 184, 0.3)
    width: auto
    height: auto
    display: flex
    align-items: center
    font-size: 14px
    line-height: 22px
</style>

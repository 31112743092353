import api from '@/helpers/api';

const state = {
    isSearch: false,
    openSearchField: false,
    currentAuthor: {},
    searchQuery: '',
    sections: [],
    movingSection: {},
    items: [],
    itemsSearch: [],
    isLoadNextPage: true,
    loadSection: true,
    loadItems: true,
    page: 1,
    detail: {},
    form: {
        title: {
            value: '',
            error: '',
        },
    },
    sectionUsers: [],
    loadEditArticle: false,
    isAutoSave: false,
    isAutoSaveOk: false,
    autoSaveLog: false,
    typographyLoad: false,
    marks: {},
    editSection: {},
    authors: [],
};
// getters
const getters = {};

const actions = {
    getSection(context) {
        context.commit('setSections', []);
        context.commit('setLoadSection', true);
        api.get('/category', {}).then((r) => {
            context.commit('setSections', r);
            context.commit('setLoadSection', false);
        });
    },
    getArticles(context, data) {
        context.commit('setNewPage');
        api.get('/articles', {
            filter: {
                category_id: data.section,
            },
        }).then((r) => {
            context.commit('setItems', r);
            context.commit('setLoadItems', false);
        });
    },
    getAuthors(context) {
        return api.get('/authors', {}).then((r) => {
            context.commit('setAuthors', r);
            return r;
        });
    },
    getArticlesSearch(context) {
        context.commit('setNewPageSearch');
        api.get('/articles/search', {
            search: state.searchQuery,
        }).then((r) => {
            context.commit('setItemsSearch', r);
            context.commit('setLoadItems', false);
        });
    },
    setSectionsSort(context) {
        const ids = [];
        for (const i in context.state.sections) {
            if (!context.state.sections[i].lock) {
                ids.push(context.state.sections[i].id);
            }
        }
        return api.put('/category/', {}, { ids: JSON.stringify(ids), operation: 'sort' }).then((r) => {
            return r;
        });
    },
    setArticlesSort(context) {
        const ids = [];
        for (const i in context.state.items) {
            ids.push(context.state.items[i].id);
        }
        return api.put('/articles/', {}, { ids: JSON.stringify(ids), operation: 'sort' }).then((r) => {
            return r;
        });
    },
    moveArticleCategory(context, data) {
        return api.put(`/articles/${data.id}`, data).then((r) => {
            context.commit('setItemCategory', data.category_id);
            return r;
        });
    },
    getArticle(context, data) {
        context.commit('setItem', {});
        context.commit('setLoadItems', true);
        api.get(`/articles/${data.id}`).then((r) => {
            context.commit('setItem', r);
            context.commit('setLoadItems', false);
        });
    },
    checkLockEdit(context, data) {
        return api.get(`/articles/${data.id}`, { operation: 'locked' }).then((r) => {
            context.commit('setItemLock', r);
            return r;
        });
    },
    setLockEdit(context, data) {
        api.put(`/articles/${data.id}/locked`).then((r) => {
            context.commit('setItemLock', r);
        });
    },
    clearLockEdit(context, data) {
        api.delete(`/articles/${data.id}/locked`).then((r) => {
            context.commit('setItemLock', r);
        });
    },
    save(context, data) {
        return api.put(`/articles/${data.id}`, {}, data.formData).then((r) => {
            if (r.description) {
                context.commit('setTypography', r.description);
            }
            if (r.event) {
                context.commit('appendLog', r);
            }
            context.commit('setItemText', { name: data.formData.get('title'), description: data.formData.get('description'), youtube: data.formData.get('youtube') });
            return r;
        });
    },
    saveNew(context, data) {
        return api.post('/articles', {}, data).then((r) => {
            context.commit('setItem', r);
            return r;
        });
    },
    uploadFiles(context, data) {
        return api.postFile('/articles', { operation: data.isSchemeImage ? 'scheme' : 'file' }, data.formData).then((r) => {
            for (const f in r) {
                context.commit('addFile', r[f]);
            }
            return r;
        });
    },
    sendStatus(context, data) {
        const sendData = { status: data.status };
        if (data.comment) {
            sendData.comment = data.comment;
        }
        return api.put(`/articles/${data.id}`, { operation: 'status' }, sendData).then((r) => {
            context.commit('sendStatus', data.status);
            if (r.item) {
                context.commit('setItem', r.item);
            }
            if (data.comment) {
                context.commit('setStatusComment', r);
            }
            if (r.event) {
                context.commit('appendLog', r);
            }
            return r;
        });
    },
    deleteStatusComment(context, data) {
        return api.delete(`/status_comment/${data}/`, {}).then((r) => {
            context.commit('deleteStatusComment', data);
            return r;
        });
    },
    archiveArticle(context, data) {
        return api.delete(`/articles/${data.id}`, {}).then((r) => {
            return r;
        });
    },
    sendTypography(context, data) {
        context.commit('setTypographyLoad', true);
        return api.put(`/articles/${data.id}`, {}, { description: data.description, operation: 'typography' }).then((r) => {
            context.commit('setTypography', r.description);
            context.commit('setTypographyLoad', false);
            return r;
        });
    },
};

const mutations = {
    setIsSearch(state, data) {
        state.isSearch = data;
        if (!data) {
            state.itemsSearch = [];
        }
    },
    openSearchField(state) {
        state.openSearchField = true;
    },
    setSearchQuery(state, data) {
        state.searchQuery = data;
    },
    setTopSections(state, data) {
        state.topSections = data;
    },
    setCurrentAuthor(state, data) {
        state.currentAuthor = data;
    },
    setSections(state, data) {
        state.sections = data;
    },
    pushSection(state, data) {
        state.sections.push(data);
    },
    updateSection(state, data) {
        for (const s in state.sections) {
            if (state.sections[s].id === data.id) {
                state.sections[s] = data;
            }
        }
    },
    deleteSection(state, data) {
        for (const s in state.sections) {
            if (state.sections[s].id === data.id) {
                state.sections.splice(+s, 1);
            }
        }
    },
    setItems(state, data) {
        state.items = data;
    },
    setAuthors(state, data) {
        state.authors = data;
    },
    setItemsSearch(state, data) {
        state.itemsSearch = data;
    },
    setItemsLocal(state, data) {
        state.items = data.items;
    },
    setNewPage(state) {
        state.items = [];
        state.itemsPage = 1;
        state.isLoadNextPage = true;
        state.loadItems = true;
    },
    setNewPageSearch(state) {
        state.itemsSearch = [];
        state.loadItems = true;
    },
    setItem(state, data) {
        state.detail = data;
    },
    setLoadSection(state, data) {
        state.loadSection = data;
    },
    setLoadItems(state, data) {
        state.loadItems = data;
    },
    setMovingTopSection(state, data) {
        state.movingSection = {};
        state.movingTopSection = data;
    },
    setMovingSection(state, data) {
        state.movingSection = data;
    },
    setItemLock(state, data) {
        state.detail.lock_edit_date = data.lock_edit_date;
        state.detail.lock_edit = data.lock_edit;
        state.detail.lock_edit_user = data.lock_edit_user;
    },
    setItemText(state, data) {
        state.detail.description = data.description;
        state.detail.name = data.name;
        state.detail.youtube = data.youtube;
    },
    setSectionUsers(state, data) {
        state.sectionUsers = data;
    },
    setFormField(state, data) {
        state.form[data.name].value = data.value;
    },
    setFormFieldError(state, data) {
        state.form[data.name].error = data.value;
    },
    setShowButtons(state, data) {
        state.showButtons = data;
    },
    setAutoSaveLog(state, data) {
        state.autoSaveLog = data;
    },
    setLoadEditArticle(state, data) {
        state.loadEditArticle = data;
    },
    setIsAutoSave(state, data) {
        state.isAutoSave = data;
    },
    setIsAutoSaveOk(state, data) {
        state.isAutoSaveOk = data;
    },
    addFile(state, data) {
        const files = typeof state.detail.files === 'object' ? [...state.detail.files] : [];
        files.push(data);
        state.detail.files = files;
    },
    setWriter(state, data) {
        state.detail.user_tech = data.user_tech;
        if (state.detail.type === 'blank' || !state.detail.type) {
            state.detail.type = 'write';
        }
    },
    appendLog(state, data) {
        state.detail.log.push(data);
    },
    setStatusComment(state, data) {
        state.detail.statusComment = data;
    },
    deleteStatusComment(state) {
        state.detail.statusComment = {};
    },
    sendStatus(state, data) {
        state.detail.status = data;
    },
    setScreencast(state, data) {
        state.detail.screencast = data.value;
    },
    setDetailRole(state, data) {
        state.detail.role_customer = data.role_customer;
        state.detail.role_expert = data.role_expert;
        state.detail.role_user = data.role_user;
        state.detail.role_comment = data.role_comment;
    },
    setTypographyLoad(state, data) {
        state.typographyLoad = data;
    },
    setTypography(state, data) {
        state.detail.description = data;
    },
    setItemCategory(state, data) {
        state.detail.category_id = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

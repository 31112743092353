const state = {
    items: {
        digits: {
            mask: '9',
            repeat: 999,
            greedy: false,
            placeholder: '',
            rightAlign: false,
            showMaskOnHover: false,
        },
        price: {
            alias: 'integer',
            placeholder: '',
            suffix: '\u00A0₽',
            autoGroup: true,
            groupSeparator: ' ',
            max: 9999999,
            allowMinus: false,
            groupSize: 3,
            autoUnmask: true,
            rightAlign: false,
            showMaskOnHover: false,
        },
        city: {
            alias: '',
            prefix: 'г. ',
        },
        percent: {
            mask: '9{1,2}%',
            autoUnmask: true,
            placeholder: '',
            rightAlign: false,
            showMaskOnFocus: true,
            showMaskOnHover: false,
        },
        phone: {
            mask: '+7(999)999-99-99',
            showMaskOnHover: false,
        },
    },

};

const getters = {
    price: (state, getters, rootState, rootGetters) => {
        const price = { ...state.items.price };
        price.suffix = `\u00A0${rootGetters['options/setRouble']}`;
        return price;
    },
    city: (state) => {
        const price = { ...state.items.city };
        price.prefix = 'г. ';
        return price;
    },
};

const actions = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/auth/',
        name: 'auth',
        component: () => import(/* webpackChunkName: "main" */ '../views/Auth.vue'),
    },
    {
        path: '/',
        name: 'rules-home',
        component: () => import(/* webpackChunkName: "main" */ '../views/Articles.vue'),
    },
    {
        path: '/sid:sid/',
        name: 'rules-top',
        component: () => import(/* webpackChunkName: "main" */ '../views/Articles.vue'),
    },
    {
        path: '/search/',
        name: 'search',
        component: () => import(/* webpackChunkName: "main" */ '../views/Search.vue'),
    },
    {
        path: '/sid:sid/:id',
        name: 'detail',
        component: () => import(/* webpackChunkName: "main" */ '../views/Detail.vue'),
        children: [
            {
                path: '',
                name: 'detail',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailContent.vue'),
            },
            {
                path: 'edit',
                name: 'detail-edit',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailEdit.vue'),
            },
            {
                path: 'move-article',
                name: 'detail-move',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailMoveArticle.vue'),
            },
            {
                path: 'delete',
                name: 'detail-delete',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailDelete.vue'),
            },
            {
                path: 'publish',
                name: 'detail-publish',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailPublish.vue'),
            },
            {
                path: 'cancel-publish',
                name: 'detail-cancel-publish',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailCancelPublish.vue'),
            },
            {
                path: 'preview',
                name: 'detail-preview',
                component: () => import(/* webpackChunkName: "main" */ '../components/rules/DetailPreview.vue'),
            },
        ],
    },
    {
        path: '/comments/',
        name: 'comments-home',
        component: () => import(/* webpackChunkName: "main" */ '../views/Comments.vue'),
    },
    {
        path: '/comments/:status',
        name: 'comments',
        component: () => import(/* webpackChunkName: "main" */ '../views/Comments.vue'),
    },
    {
        path: '/lk/',
        name: 'lk',
        component: () => import(/* webpackChunkName: "main" */ '../views/Lk.vue'),
    },
    {
        path: '/docs/',
        name: 'docs',
        component: () => import(/* webpackChunkName: "main" */ '../views/Docs.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;

<template>
    <div class="site-updated">
        <div class="zems"></div>
        <div class="text-size-14 mt16 text-center">
            Друзья, мы обновили сайт!<br>
            Пожалуйста, обновите страницу, чтобы изменения вступили в силу.
        </div>
        <a href="." @click.prevent="updateVersion()" class="btn btn--ico mt16">
            Спасибо, Екатерина!
        </a>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'NotifyReset',
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
        }),
    },
    methods: {
        updateVersion() {
            window.location.reload();
        },
    },
};
</script>

<style lang="sass">
.site-updated
    width: 280px
    height: auto
    box-shadow: 0px 0px 16px rgba(176, 197, 222, 0.5)
    border-radius: 4px
    background-color: #fff
    padding: 36px 24px
    position: fixed
    bottom: 24px
    right: 24px
    z-index: 100000
    display: flex
    flex-direction: column
    align-items: center
    .text-size-m
        line-height: 22px !important
    .zems
        background-image: url(../assets/img/ekaterina.jpg)
        background-repeat: no-repeat
        width: 64px
        height: 64px
        background-position: -63px -12px
        border-radius: 64px
        border: 1px solid $yellow
        background-size: 153px
</style>

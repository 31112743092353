const state = {
    curItemData: {},
    canDrag: false,
    items: {
        adminEditable: {
            value: false,
            name: 'adminEditable',
        },
        editable: {
            value: false,
            name: 'editable',
        },
        teamEditable: {
            value: false,
            name: 'teamEditable',
        },
        sectionsSettingsEditable: {
            value: false,
            name: 'sectionsSettingsEditable',
        },
        contactsEditable: {
            value: false,
            name: 'contactsEditable',
        },
        contractsEditable: {
            value: false,
            name: 'contractsEditable',
        },
        workerEditable: {
            value: false,
            name: 'workerEditable',
        },
        zoom: {
            value: false,
            name: 'zoom',
        },
        ruleEdit: {
            value: false,
            name: 'ruleEdit',
        },
        moveRule: {
            value: false,
            name: 'moveRule',
        },
    },
};

// getters
const getters = {

};

const actions = {

};

const mutations = {
    toggleDrag(state) {
        state.canDrag = !state.canDrag;
    },
    toggleSwitch(state, data) {
        state.items[data].value = !state.items[data].value;
    },
    offSwitch(state, data) {
        state.items[data].value = false;
    },
    onSwitch(state, data) {
        state.items[data].value = true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
